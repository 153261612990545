"use client";

import { useEffect } from "react";

const FacebookPixel = () => {
  useEffect(() => {
    // Dynamically import react-facebook-pixel
    import("react-facebook-pixel")
      .then((ReactPixel) => {
        ReactPixel.default.init("1279245323068718");
        ReactPixel.default.pageView(); // Track initial page view
      })
      .catch((error) => {
        console.error("Failed to load react-facebook-pixel", error);
      });
  }, []);

  return null;
};

export default FacebookPixel;
